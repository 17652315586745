<div [formGroup]="form" *ngIf="loading === false">
  <div *ngIf="maxCustomFonts === 0">
    <p class="font-upload-disabled">
      <app-icon name="warning" weight="fill"></app-icon>
      Font upload is disabled. This agency has reached its max limit of custom
      fonts.
    </p>
  </div>

  <div
    *ngIf="
      maxCustomFonts !== undefined &&
      maxCustomFonts > 0 &&
      maxCustomFonts < files.length
    "
  >
    <p class="font-upload-disabled">
      <app-icon name="warning" weight="fill"></app-icon>
      You cannot upload that many files, since that would surpass the max custom
      font limit.
    </p>
  </div>

  <sk-file-drop
    theme="light"
    (fileDrop)="dropped($event)"
    [useZip]="true"
    dropZoneLabel="Drag and drop files or click to upload"
    [showBrowseBtn]="true"
    [isLoading]="isUploading"
    accept=".ttf,.otf"
  ></sk-file-drop>
  <span
    [matTooltip]="
      maxCustomFonts === 0
        ? 'Font upload is disabled. This agency has reached its max limit of custom fonts.'
        : maxCustomFonts !== undefined &&
            maxCustomFonts > 0 &&
            maxCustomFonts < files.length
          ? 'You cannot upload that many files, since that would surpass the max custom font limit.'
          : disableUpload
            ? 'Add a file to upload'
            : ''
    "
  >
    <button
      mat-flat-button
      color="primary"
      (click)="uploadFont()"
      class="u-marginAs"
      matTooltip="Upload font "
      [disabled]="
        disableUpload ||
        maxCustomFonts === 0 ||
        (maxCustomFonts !== undefined && maxCustomFonts < files.length)
      "
    >
      Upload font
    </button>
  </span>

  <ng-container *ngIf="dataSource.length > 0; else noFonts">
    <table
      mat-table
      [dataSource]="displayedFonts"
      [multiTemplateDataRows]="true"
      class="mat-elevation-z0 u-width--full"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of headerColumns"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ actions: notDisplayedColumns.includes(column) }"
        >
          <ng-container
            *ngIf="notDisplayedColumns.includes(column); else showHeader"
          >
            <span style="display: none">{{ column }}</span>
          </ng-container>

          <ng-template #showHeader>
            {{ column }}
          </ng-template>
        </th>
        <ng-container *matCellDef="let element; let dataIndex = dataIndex">
          <td *ngIf="column === 'font'" class="font limit-text">
            <span
              class="u-color--medium u-iconsize--s cursor-pointer"
              (click)="
                expandedElement = expandedElement === element ? null : element
              "
            >
              <app-icon
                [name]="
                  expandedElement === element ? 'arrow-down' : 'arrow-right'
                "
                weight="fill"
              ></app-icon>
            </span>
            <span [matTooltip]="element.fontName"
              >{{ element.fontName }}
              <span
                class="font-unsaved-change"
                *ngIf="hasUnsavedFontValuesChanges[dataIndex]"
              >
                !
              </span>
            </span>
          </td>
          <td class="custom-weight-header" *ngIf="column === 'weight'">
            {{ element.fontWeight }}
          </td>
          <td class="custom-size-header" *ngIf="column === 'size'">
            {{ element.large.size }}
          </td>
          <td class="custom-leading-header" *ngIf="column === 'leading'">
            {{ element.large.leading }}
          </td>
          <td class="custom-tracking-header" *ngIf="column === 'tracking'">
            {{ element.large.tracking }}
          </td>
          <td class="custom-capHeight-header" *ngIf="column === 'capHeight'">
            {{ element.large.capHeight }}
          </td>
          <td class="custom-xHeight-header" *ngIf="column === 'xHeight'">
            {{ element.large.xHeight }}
          </td>
          <td
            class="custom-uploaded-by-header"
            *ngIf="column === 'uploaded-by'"
          >
            {{ element.createdBy?.name }}
          </td>
          <td
            *ngIf="column === 'lock' && !this.disabledInput.has(dataIndex)"
            class="u-text-align--center"
          >
            <button
              class="u-color--medium u-iconsize--s no-button-style"
              (click)="lockRow(dataIndex, element?._id)"
              matTooltip="Locks selection for changes"
            >
              <app-icon
                name="lock-open"
                weight="fill"
                class="o-btn__icon"
              ></app-icon>
            </button>
          </td>
          <td
            *ngIf="column === 'lock' && this.disabledInput.has(dataIndex)"
            class="u-text-align--center"
          >
            <button
              class="-color--dark u-iconsize--s no-button-style"
              matTooltip="Unlocks selection for changes"
              (click)="unlockRow(dataIndex, element?._id)"
            >
              <app-icon
                name="lock"
                weight="fill"
                class="o-btn__icon"
              ></app-icon>
            </button>
          </td>
          <td *ngIf="column === 'download'" class="u-text-align--center">
            <button
              class="u-color--dark u-iconsize--m no-button-style"
              matTooltip="Download font"
              (click)="downloadFont(dataIndex)"
            >
              <app-icon
                name="cloud-arrow-down"
                weight="fill"
                class="o-btn__icon"
              ></app-icon>
            </button>
          </td>
          <td *ngIf="column === 'trashcan'" class="u-text-align--center">
            <span
              [matTooltip]="
                element.global
                  ? 'This font is global and cannot be deleted'
                  : 'Delete font'
              "
            >
              <button
                class="u-iconsize--m no-button-style"
                [ngClass]="{
                  'u-color--gray': element.global,
                  'u-color--negative': !element.global,
                }"
                [disabled]="element.global"
                (click)="openDeleteDialog(dataIndex)"
              >
                <app-icon
                  name="trash"
                  weight="fill"
                  class="o-btn__icon"
                ></app-icon>
              </button>
            </span>
          </td>
          <td *ngIf="column === 'action'" class="u-text-align--center">
            <div class="mat-select-wrapper">
              <mat-form-field>
                <mat-select
                  [formControl]="clientsFontControls[element?._id]"
                  multiple
                  *ngIf="
                    fontClients[element?._id] &&
                    fontClients[element?._id].length !== 0
                  "
                  [compareWith]="objectComparisonFunction"
                  [matTooltip]="
                    clientsFontControls[element?._id].disabled
                      ? 'Unlock a font to change clients access'
                      : 'Clients access to the font'
                  "
                >
                  <mat-select-trigger></mat-select-trigger>
                  <mat-option
                    *ngFor="let client of fontClients[element?._id]"
                    [value]="client"
                    >{{ client?.name }}</mat-option
                  >
                </mat-select>

                <!-- need to show only no clients option-->
                <!-- mat-select don't support dynamic [multiple] option -->
                <!-- https://material.angular.io/components/select/overview#error-cannot-change-multiple-mode-of-select-after-initialization -->
                <mat-select
                  [formControl]="clientsFontControls[element?._id]"
                  *ngIf="fontClients[element?._id]?.length === 0"
                  [matTooltip]="
                    clientsFontControls[element?._id].disabled
                      ? 'Unlock a font to change clients access'
                      : 'Please create at least one client'
                  "
                >
                  <mat-select-trigger></mat-select-trigger>
                  <mat-option
                    [value]="null"
                    disabled="true"
                    *ngIf="fontClients[element?._id]?.length === 0"
                    >No clients to choose</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          *matCellDef="let element; let dataIndex = dataIndex"
          [attr.colspan]="headerColumns.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="
              element === expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div
              formArrayName="fontSettings{{ dataIndex }}"
              *ngFor="let access of fontFormControls | async; let i = index"
            >
              <div
                class="u-flex"
                [formGroupName]="i"
                [class.disabled-row]="this.disabledInput.has(dataIndex)"
              >
                <mat-cell
                  style="flex: 0 0 18%"
                  *ngIf="
                    access.value.fontStyle === masterFont;
                    else notMasterFont
                  "
                >
                  <b>{{ access.value.fontStyle }} (Master) </b>
                </mat-cell>

                <ng-template #notMasterFont>
                  <mat-cell style="flex: 0 0 18%">
                    {{ access.value.fontStyle }}
                  </mat-cell>
                </ng-template>

                <mat-cell class="flex-14">
                  <mat-form-field class="form-field-small dash">
                    <input
                      type="number"
                      matInput
                      formControlName="size"
                      placeholder="size"
                      autocomplete="off"
                      (change)="onFontValuesChange(dataIndex)"
                    />
                  </mat-form-field>
                </mat-cell>
                <mat-cell class="flex-14">
                  <mat-form-field class="form-field-small dash">
                    <input
                      type="number"
                      matInput
                      formControlName="leading"
                      placeholder="leading"
                      autocomplete="off"
                      (change)="onFontValuesChange(dataIndex)"
                    />
                  </mat-form-field>
                </mat-cell>
                <mat-cell class="flex-14">
                  <mat-form-field class="form-field-small dash">
                    <input
                      type="number"
                      matInput
                      formControlName="tracking"
                      placeholder="tracking"
                      autocomplete="off"
                      (change)="onFontValuesChange(dataIndex)"
                    />
                  </mat-form-field>
                </mat-cell>
                <mat-cell class="flex-14">
                  <mat-form-field class="form-field-small dash">
                    <input
                      type="number"
                      matInput
                      formControlName="capHeight"
                      placeholder="cap height"
                      autocomplete="off"
                      (change)="onFontValuesChange(dataIndex)"
                    />
                  </mat-form-field>
                </mat-cell>
                <mat-cell>
                  <mat-form-field class="form-field-small">
                    <input
                      type="number"
                      matInput
                      formControlName="xHeight"
                      placeholder="x height"
                      autocomplete="off"
                      (change)="onFontValuesChange(dataIndex)"
                    />
                  </mat-form-field>
                </mat-cell>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headerColumns"></tr>
      <tr
        mat-row
        *matRowDef="
          let element;
          columns: headerColumns;
          let dataIndex = dataIndex
        "
        [class.expanded-row]="expandedElement === element"
        [class.disabled-row]="this.disabledInput.has(dataIndex)"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </table>

    <mat-paginator
      [length]="dataSource.length"
      (page)="pageInit($event)"
      [pageSizeOptions]="[5, 10, 20]"
      [showFirstLastButtons]="true"
    ></mat-paginator>
    <div class="container container--small u-paddingBm u-paddingTm">
      <span [matTooltip]="!form.enabled ? 'Unlock a font to update' : ''">
        <button
          mat-flat-button
          color="primary"
          (click)="updateFonts()"
          matTooltip="Updates unlocked fonts for agency"
          [disabled]="formLoading || form.invalid || !form.enabled"
        >
          Update font values
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="updateClients()"
          class="u-marginLs"
          matTooltip="Updates client access for fonts"
          [disabled]="formLoading || form.invalid || !form.enabled"
        >
          Update client access
        </button>
      </span>
    </div>
  </ng-container>
  <ng-template #noFonts>
    <div class="container container--small u-text-align--center">
      <p>No fonts uploaded to agency</p>
    </div>
  </ng-template>
</div>
