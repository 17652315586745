import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MATERIAL_MODULES } from '@shared/material-design/material-modules';
import { NgIf } from '@angular/common';

export interface FreeTrialForm {
  freeTrial: FormControl<boolean>;
  freeTrialExpirationDate: FormControl<Date>;
}

@Component({
  selector: 'app-free-trial-form',
  templateUrl: './free-trial-form.component.html',
  styleUrls: ['../../../styles/settings-form.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MATERIAL_MODULES, NgIf],
})
export class FreeTrialFormComponent implements OnInit, OnDestroy {
  form = input.required<FormGroup<FreeTrialForm>>();

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.form()
      .get('freeTrial')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((freeTrialEnabled) => {
        const freeTrialDefaultEnd: Date = new Date(
          Date.now() + 1000 * 60 * 60 * 24 * 14 // 14 days from now
        );

        if (freeTrialEnabled) {
          this.form().patchValue({
            freeTrialExpirationDate: freeTrialDefaultEnd,
          });
        } else {
          this.form().patchValue({
            freeTrialExpirationDate: undefined,
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
