import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-freemium-form',
  templateUrl: './freemium-form.component.html',
  styleUrls: ['../../../styles/settings-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, MatLabel, MatFormField, MatInput],
})
export class FreemiumFormComponent {
  form = input.required<FormGroup>();
}
